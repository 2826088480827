import { createContext, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useWebSocket from 'react-use-websocket'
import { getFromLocalStorage } from '../../../utils/getFromLocalStorage'
import { removeFromLocalStorage } from '../../../utils/removeFromLocalStorage'
import { STORAGE_KEY, STORAGE_QUEUE } from '../utils/constants'

export type Ticket = {
  guiche: string
  nome: string
  senha: string
  servico_titulo: string
  horario: string
}

export type QueueContextProps = {
  ticketQueue: Ticket[]
  recentTickets: Ticket[]
  currentTicket: Ticket | undefined
}

export const QueueManagementContext = createContext<QueueContextProps | null>(null)

export function QueueManagementProvider({ children }: { children: ReactNode }): JSX.Element {
  const { id: roomId } = useParams<{ id: string }>()
  const unitData = localStorage.getItem('gov_ticket_office')
  const unit = unitData ? JSON.parse(unitData) : null
  const slug_unidade = unit?.setor?.unidade?.slug_unidade || unit?.unidade?.slug_unidade
  const initialQueue = getFromLocalStorage<Ticket[]>(STORAGE_QUEUE) || []
  const [ticketQueue, setTicketQueue] = useState<Ticket[]>(initialQueue)
  const [recentTickets, setRecentTickets] = useState<Ticket[]>([])
  const [currentTicket, setCurrentTicket] = useState<Ticket | undefined>(undefined)
  const [socketParams, setSocketParams] = useState({
    hostname: roomId  || 'test',
    room_name: String(slug_unidade) || ''
  })
  const [socketInitialized, setSocketInitialized] = useState(false)

  const updateTicketQueue = (newTicket: Ticket): void => {
    const updatedQueue = [...ticketQueue, newTicket]
    setTicketQueue(updatedQueue)
    localStorage.setItem(STORAGE_QUEUE, JSON.stringify(updatedQueue))
  }

  const clearStorageKeys = () => {
    removeFromLocalStorage(STORAGE_KEY)
    removeFromLocalStorage(STORAGE_QUEUE)
  }

  const SOCKET_URL = (window as any)._env_.REACT_APP_WEBSOCKET_PAINEL_WS ?? ''

  const { getWebSocket, readyState, sendJsonMessage } = useWebSocket(SOCKET_URL, {
    queryParams: socketParams,
    onOpen: () => {
      if (!socketInitialized) {
        toast.success('Conexão estabelecida com sucesso!')
        setSocketInitialized(true)
      }
    },
    onClose: () => {
      toast.warn('Conexão perdida. Tentando reconectar...')
      setSocketInitialized(false)
    },
    onError: (error: any) => {
      toast.error(`Erro na conexão WebSocket: ${error.message}`)
    },
    filter: (message) => {
      try {
        const parsedData = JSON.parse(message.data)
        if (parsedData.payload && parsedData.payload?.senha) {
          const ticket: Ticket = {
            ...parsedData.payload,
            horario: new Date().toLocaleTimeString()
          }
          if(ticket.senha !== currentTicket?.senha) {
            updateTicketQueue(ticket)
          }
          setCurrentTicket(ticket)
        }
      } catch (error) {
        toast.error('Erro ao processar a mensagem.')
      }
      return true
    }
  })

  useEffect(() => {
    if (readyState !== 1) return

    const interval = setInterval(() => {
      sendJsonMessage({ type: 'PING' })
    }, 2 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [readyState, sendJsonMessage])

  useEffect(() => {
    if (ticketQueue.length > 0) {
      const nextTicket = ticketQueue[0];

      if (!currentTicket || currentTicket.senha !== nextTicket.senha) {
        if (currentTicket) {
          setRecentTickets((prevTickets) => {
            const updatedTickets = [currentTicket, ...prevTickets].slice(0, 3);
            return updatedTickets;
          });
        }

        setCurrentTicket(nextTicket);
        setTicketQueue((prevQueue) => prevQueue.slice(1));
      }
    }
  }, [ticketQueue, currentTicket]);


  useEffect(() => {
    if (roomId) {
      setSocketParams((prevParams) => ({
        ...prevParams,
        hostname: roomId || 'test',
        room_name: slug_unidade || ''
      }))
    }
  }, [roomId, slug_unidade])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (readyState === 1) {
        getWebSocket()?.close()
        console.log('WebSocket fechado antes de sair')
      }

      clearStorageKeys()

      event.preventDefault()
      event.returnValue = ''
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [getWebSocket])

  const context: QueueContextProps = {
    recentTickets,
    ticketQueue,
    currentTicket
  }

  return (
    <QueueManagementContext.Provider value={context}>{children}</QueueManagementContext.Provider>
  )
}
