import { Box, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import useStyles from "./style";

const Header = () => {
  const classes = useStyles();
  const unitData = localStorage.getItem("gov_ticket_office");
  const unidade = unitData ? JSON.parse(unitData) : null;
  const [horaAtual, setHoraAtual] = useState(new Date());

  useEffect(() => {
    const intervalo = setInterval(() => {
      setHoraAtual(new Date());
    }, 1000);

    return () => clearInterval(intervalo);
  }, []);

  const dataFormatoPadrao = format(horaAtual, "dd/MM/yyyy");

  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>
        {unidade?.setor ? unidade?.setor?.unidade?.nome : unidade?.unidade?.nome}{"  "}
        {format(horaAtual, 'HH:mm:ss')} {dataFormatoPadrao}
      </Typography>
    </Box>
  );
};

export default Header;