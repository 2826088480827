import { Box, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useServiceLocationContext } from '../../hooks/useServiceLocationContext';
import { CallPanelPreviousList } from '../callPanelPreviousList';
import { useStyles } from './style';

export const CustomerCall = (): JSX.Element => {
  const classes = useStyles();
  const { currentTicket, recentTickets } = useServiceLocationContext();
  const [isFocusingCurrent, setIsFocusingCurrent] = useState(false);

  useEffect(() => {
    if (currentTicket) {
      setIsFocusingCurrent(true);

      const { senha, guiche } = currentTicket;

      if ('speechSynthesis' in window) {
        const synthesis = window.speechSynthesis;
        const utterance = new SpeechSynthesisUtterance(
          `Atendimento número ${senha}. Por favor, dirija-se ao guichê ${guiche}.`
        );
        synthesis.speak(utterance);
      } else {
        toast.error('A funcionalidade de síntese de voz não é suportada neste dispositivo.');
      }

      // Após 5 segundos, remover o foco e exibir o histórico
      const timeoutId = setTimeout(() => {
        setIsFocusingCurrent(false);
      }, 5000);

      return () => {
        if ('speechSynthesis' in window) {
          window.speechSynthesis.cancel();
        }
        clearTimeout(timeoutId);
      };
    }
  }, [currentTicket]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.title}>
          <Typography variant="h6" className={classes.label}>Última Senha chamada</Typography>
        </Box>
        <Box
          className={`${classes.currentInfo} ${isFocusingCurrent ? classes.zoomEffect : ''}`}
        >
          {currentTicket ? (
            <>
              <Box className={classes.currentBox}>
                <Typography variant="h4" className={classes.currentLabel}>Senha</Typography>
                <Typography variant="h3" className={classes.currentNumber}>
                  {currentTicket?.senha || 'N/A'}
                </Typography>
              </Box>
              <Box className={classes.currentBox}>
                <Typography variant="h4" className={classes.currentLabel}>GUICHÊ</Typography>
                <Typography variant="h3" className={classes.currentNumber}>
                  {currentTicket?.guiche || 'N/A'}
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="h3" className={classes.noInfo}>NenhumA SENHA em andamento no momento</Typography>
            </Box>
          )}
        </Box>
      </Box>
      {!isFocusingCurrent && currentTicket && (recentTickets.length  > 0) && <CallPanelPreviousList />}
    </>
  );
};
